import { filter } from "lodash"

const clinics = [
  {
    name: "GastroCirúrgica",
    slug: "gastrocirurgica",
    url: "https://gastrocirurgica.com.br",
    address:
      "Baía Sul Medical Center Sala 115\nRua Menino Deus, 63\nCentro, Florianópolis - SC\n88020-210",
    phone: "(48) 3207-2050",
    city: "Florianópolis - SC",
    doctors: {
      dr_cristiano_denoni_freitas: true,
      dr_joao_paulo_farias: true,
      dr_eduardo_miguel_schmidt: true,
      dr_marcelo_borges_moreira: true,
      dr_carlos_augusto_chede_rotolo: true,
    },
  },
  {
    name: "Endogastro",
    slug: "endogastro",
    url: "http://endogastro.com.br",
    address:
      "Baía Sul Medical Center Sala 506\nRua Menino Deus, 63\nCentro, Florianópolis - SC\n88020-210",
    phone: "(48) 3224-8899",
    whatsapp: {
      label: "(48) 99855-0034",
      url: "5548998550034",
    },
    city: "Florianópolis - SC",
    doctors: {
      dr_cristiano_denoni_freitas: true,
      dr_joao_paulo_farias: true,
      dr_wilmar_athayde_gerent: true,
      dr_marcelo_borges_moreira: true,
    },
  },
  {
    name: "Viver Clínica Médica",
    slug: "viverclinicamedica",
    url: "https://viverclinicamedica.com.br",
    address:
      "Viver Clínica Médica\nRua Angelo La Porta, 64 \nCentro, Florianópolis - SC\n88020-600",
    phone: "(48) 3324-1100",
    city: "Florianópolis - SC",
    doctors: {
      dr_cristiano_denoni_freitas: true,
    },
  },
  {
    name: "Vidália Medical Center",
    slug: "vidaliamedicalcenter",
    url: "https://www.vidaliamedical.com.br/",
    address:
      "Vidália Medical Center\nR. Elizeu di Bernardi, 34 \nCampinas, São José - SC\n88101-050",
    phone: "(48) 3121-0031",
    whatsapp: {
      label: "(48) 3121-0031",
      url: "554831210031",
      text: "Ol%C3%A1,%20Gostaria%20de%20agendar%20consulta"
    },
    city: "Florianópolis - SC",
    doctors: {
      dr_cristiano_denoni_freitas: true,
      dr_marcelo_borges_moreira: true,
      dr_carlos_augusto_chede_rotolo: true,
      dr_joao_paulo_farias: true,
    },
  },
  {
    name: "Centro Médico Baía Sul",
    slug: "baiasul",
    url: "https://hospitalbaiasul.com.br/",
    address:
      "Baía Sul Medical Center\nRua Menino Deus, 63\nCentro, Florianópolis - SC\n88020-210",
    whatsapp: {
      label: "(48) 99158-7696",
      url: "554891587696",
      text: "Ol%C3%A1,%20Gostaria%20de%20agendar%20consulta%20com%20Dr.Rafael%20Fernandes%20de%20Carvalho%20em%20Pequenos%20procedimentos"
    },
    city: "Florianópolis - SC",
    doctors: {
      dr_rafael_fernandes_de_carvalho: true,
    },
  },
  {
    name: "Ambulatório Hospital Unimed Grande Florianópolis",
    slug: "unimed",
    url: "http://www.unimedflorianopolis.com.br/hospital/",
    address: "Rua Manoel Loureiro, 1909\nBarreiros, São José - SC\n88117-331",
    whatsapp: {
      label: "0800 048 3500",
      url: "5508000483500",
      text: "Ol%C3%A1,%20Gostaria%20de%20agendar%20consulta%20com%20Dr.Rafael%20Fernandes%20de%20Carvalho%20em%20Pequenas%20cirurgias%20no%20ambulat%C3%B3rio%20do%20Hospital%20UNIMED%20Grande%20Florian%C3%B3polis(UGF)"
    },
    city: "Florianópolis - SC",
    doctors: {
      dr_rafael_fernandes_de_carvalho: true,
    },
  },
  {
    slug: "caridade",
    name: "Centro Médico Imperial Hospital de Caridade",
    address: "Rua Menino Deus, 376\nCentro, Florianópolis - SC\n88020-210",
    url: "https://www.hospitaldecaridade.com.br",
    whatsapp: {
      label: "(48) 998803-6888",
      url: "55489988036888",
    },
    city: "Florianópolis - SC",
    doctors: {
      dr_rafael_fernandes_de_carvalho: true,
    },
  },
]

export const getClinicsByDoctor = ({ slug }) =>
  filter(clinics, `doctors.${slug}`)

export const getClinicsBySlugs = ({ slugs }) =>
  filter(clinics, item => Object.keys(slugs).includes(item.slug)).map(
    clinic => ({
      ...clinic,
      doctors: slugs[clinic.slug].doctors,
    })
  )

export const getDoctorsSlugsWithClinic = () =>
  clinics.reduce((red, clinic) => {
    return {
      ...red,
      ...clinic.doctors
    }
  }, {})

export const getDoctorsWithClinic = (doctors) => {
  const slugs = getDoctorsSlugsWithClinic()
  return doctors.filter(({ slug }) => slugs[slug])
}

export default clinics
