import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Section from "../components/section"
import Container from "../components/container"
import SEO from "../components/seo"
import Flex from "../components/flex"
import Typography from "../components/typography"
import Button from "../components/button"
import { useImagesFluid } from "../hooks/useImages"
import formatToList from "../utils/formatToList"
import { getClinicsByDoctor } from "../api/clinics"
import { getHospitalsByDoctor } from "../api/hospitals"

const getDescription = ({ name, titles }) =>
  `${name} cirurgião especialista em ${formatToList(
    titles.map(({ label }) => label)
  )} em Florianópolis/SC`

const WrapperImage = styled.div`
  @media (min-width: 960px) {
    margin-bottom: -52rem;
  }
  @media (max-width: 960px) {
    margin-bottom: 2.5rem;
  }
`

const Image = styled(Img)`
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
  border-radius: 50px 0 50px 0;
`

const Ul = styled.ul`
  list-style: disc;
  margin-left: 15px;
`

const Doctor = ({
  pageContext: { slug, name, titles, crm, image, resume, lattes, schedule, illnesses },
  ...rest
}) => {
  const imageFluid = useImagesFluid({ names: [image] })
  const clinics = getClinicsByDoctor({ slug })
  const hospitals = getHospitalsByDoctor({ slug })
  return (
    <Layout {...rest}>
      <SEO title={name} description={getDescription({ name, titles })} />
      <Section background="secondary" padding="2.5rem 0">
        <Container>
          <Flex flexWrap="wrap" align="flex-start" justify="space-between">
            <Flex child flex={100} flexGtSm={25}>
              <WrapperImage>
                <Image fluid={imageFluid[0].node.fluid} alt={name} />
              </WrapperImage>
            </Flex>
            <Flex child flex={100} flexGtSm={70}>
              <Typography variant="h1" component="h1" color="light">
                {name}
              </Typography>
              <Typography variant="h6" component="p" color="primary">
                {`CRM ${crm}`}
              </Typography>
              {titles.map(({ rqe, label }) => (
                <Typography
                  key={rqe}
                  variant="body"
                  component="p"
                  color="light"
                  removeMargin
                >
                  {`${label} (RQE ${rqe})`}
                </Typography>
              ))}
            </Flex>
          </Flex>
        </Container>
      </Section>
      <Section padding="2rem 0">
        <Container>
          <Flex flexWrap="wrap" align="flex-start" justify="space-between">
            <Flex child flex={100} flexGtSm={25} />
            <Flex child flex={100} flexGtSm={70}>
              <Ul>
                {resume.map(item => (
                  <li>
                    <Typography variant="bodyLarge" removeMargin>
                      {item}
                    </Typography>
                  </li>
                ))}
              </Ul>
              {illnesses && (
                <section style={{ marginTop: "2rem" }}>
                  <Typography variant="h6" component="h2">
                    Doenças tratadas
                  </Typography>
                  <Ul>
                    {illnesses.map(illness => (
                      <li>
                        <Typography variant="bodyLarge" removeMargin>
                          {illness}
                        </Typography>
                      </li>
                    ))}
                  </Ul>
                </section>
              )}
              {clinics.length > 0 && (
                <section style={{ marginTop: "2rem" }}>
                  <Typography variant="h6" component="h2">
                    Clínicas
                  </Typography>
                  <Ul>
                    {clinics.map(clinic => (
                      <li>
                        <Typography
                          variant="bodyLarge"
                          removeMargin
                          component="a"
                          href={clinic.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {clinic.name}
                        </Typography>
                      </li>
                    ))}
                  </Ul>
                </section>
              )}
              <section style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <Typography variant="h6" component="h2">
                  Hospitais
                </Typography>
                <Ul>
                  {hospitals.map(hospital => (
                    <li>
                      <Typography
                        variant="bodyLarge"
                        removeMargin
                        component="a"
                        href={hospital.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {hospital.name}
                      </Typography>
                    </li>
                  ))}
                </Ul>
              </section>
              {lattes && (
                <Button
                  href={lattes}
                  target="_blank"
                  htmlType="a"
                  color="primary"
                  shape="round"
                  size="large"
                >
                  Ver currículo
                </Button>
              )}
              {schedule && (
                <Button
                  href={schedule}
                  target="_blank"
                  color="link"
                  size="large"
                  shape="round"
                  htmlType="link"
                >
                  Agendar consulta
                </Button>
              )}
            </Flex>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

Doctor.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Doctor
