import { filter } from "lodash"

const hospitals = [
  {
    name: "Imperial Hospital de Caridade",
    address: "Rua Menino Deus, 376\nCentro, Florianópolis - SC\n88020-210",
    phone: "(48) 3221-7500",
    url: "https://www.hospitaldecaridade.com.br",
    doctors: {
      dr_cristiano_denoni_freitas: true,
      dr_eduardo_miguel_schmidt: true,
      dr_joao_paulo_farias: true,
      dr_mauricio_mendes_albuquerque: true,
      dr_wilmar_athayde_gerent: true,
      dr_marcelo_borges_moreira: true,
      dr_carlos_augusto_chede_rotolo: true,
      dr_rafael_fernandes_de_carvalho: true,
    },
  },
  {
    name: "Hospital Baía Sul Medical Center",
    address: "Rua Menino Deus, 63\nCentro, Florianópolis - SC\n88020-210",
    phone: "(48) 3221-7500",
    url: "https://www.hospitalbaiasul.com.br/",
    doctors: {
      dr_cristiano_denoni_freitas: true,
      dr_eduardo_miguel_schmidt: true,
      dr_joao_paulo_farias: true,
      dr_mauricio_mendes_albuquerque: true,
      dr_wilmar_athayde_gerent: true,
      dr_marcelo_borges_moreira: true,
      dr_carlos_augusto_chede_rotolo: true,
      dr_rafael_fernandes_de_carvalho: true,
    },
  },
  {
    name: "Hospital Unimed Grande Florianópolis",
    address: "Rua Manoel Loureiro, 1909\nBarreiros, São José - SC\n88117-331",
    url: "http://www.unimedflorianopolis.com.br/hospital/",
    phone: "(48) 3288-4100",
    doctors: {
      dr_cristiano_denoni_freitas: true,
      dr_eduardo_miguel_schmidt: true,
      dr_joao_paulo_farias: true,
      dr_mauricio_mendes_albuquerque: true,
      dr_marcelo_borges_moreira: true,
      dr_carlos_augusto_chede_rotolo: true,
      dr_rafael_fernandes_de_carvalho: true,
    },
  },
]

export const getHospitalsByDoctor = ({ slug }) =>
  filter(hospitals, `doctors.${slug}`)

export default hospitals
