const splitOnLast = value => [
  value.slice(0, value.length - 1),
  value[value.length - 1],
]

export default value => {
  if (value.length === 0) return ""
  if (value.length === 1) return value[0]
  const [everyButLast, last] = splitOnLast(value)
  return `${everyButLast.join(", ")} e ${last}`
}
